export type HTTP_METHOD =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

type body_types =
  | Blob
  | BufferSource
  | FormData
  | URLSearchParams
  | ReadableStream<Uint8Array>
  | string

interface IFetchParams {
  endpoint: string;
  token?: string;
  method: HTTP_METHOD;
  body?: body_types;
  accept?: string;
  contentType?: string;
  cache?: RequestCache;
  contentTypeOptions?: string;
  queryParams?: {
    [key: string]: any
  };
}

interface IApiError {
  message: string;
  status: string;
  url: string;
}

export class Api {
  accessToken?: string;


  fetch = async <T = any>({
                            endpoint,
                            method = 'GET',
                            body,
                            accept = 'application/*',
                            contentType = 'application/json',
                            cache,
                            contentTypeOptions,
                            queryParams
                          }: IFetchParams): Promise<T> => {
    const headers: Headers = new Headers();
    if (contentType !== 'auto') {
      headers.append('Content-Type', contentType);
    }
    if (accept) {
      headers.append('Accept', accept);
    }
    if (contentTypeOptions) {
      headers.append('X-Content-Type-Options', contentTypeOptions);
    }

    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
    headers.append('credentials', 'include');

    let queryString = '';
    if (queryParams && Object.keys(queryParams).some((key) => queryParams[key] !== undefined)) {
      queryString += '?';
      queryString += Object.keys(queryParams)
        .map((key) => {
          if (queryParams[key] === undefined) return undefined;
          return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
        })
        .filter(Boolean)
        .join('&');
    }

    return fetch(endpoint.concat(queryString), {
      headers,
      cache,
      method,
      body
    })
      .then(
        async (response) => {
          if (!response.ok) {
            let data = null;
            if (response.headers.get('content-type')?.includes('application/json')) {
              data = await response.json();
            }
            if (response.status === 401 || response.status === 422 ) {
              window.location.href = '/login'; // Gehe zur Login-Seite
            }
            return Promise.reject({
              message: (data && data.message) || response.statusText,
              status: response.status,
              url: response.url
            });
          }
          if (response.status === 200) {
            if (response.headers.get('content-type')?.includes('application/json')) {
              return response.json();
            }

            if (response.headers.get('content-type')?.includes('image/png')) {
              return response.arrayBuffer().then((buffer: ArrayBuffer) => {
                return new Buffer(buffer).toString('base64');
              });
            }
            if (response.headers.get('content-type')?.includes('text/csv')) {
              return response.blob().then((blob) => {
                window.location.assign(window.URL.createObjectURL(blob));
                return;
              });
            }
          }


          return response;
        },
        (error) => {
          console.error(`Es ist ein Fehler beim Laden des Datensatzes aufgetreten`, error);
          return new Error(error);
        }
      )
      .catch((e: IApiError) => {
        const title = `${e.status}`;
        const message = `${e.message}\n${e.url}`;
        window.dispatchEvent(
          new CustomEvent('ApiErrorEvent', {
            detail: {
              title,
              message
            }
          })
        );
      });
  };
}
import { makeAutoObservable } from 'mobx';
import { useDependency } from '../injections';
import { Store } from './store';
import { Job, News } from './entity';
import moment from 'moment/moment';

export class Controller {
  store = new Store();

  constructor() {
    makeAutoObservable(this);
  }

  get getJobsItems() {
    return this.store.jobItem;
  }

  get getJobs() {
    return this.store.jobs;
  }

  get getNewsItem() {
    return this.store.newsItem;
  }

  async fetchNews(item: string) {
    await this.store.getNews(item);
    await this.getNewsTrends(item);
  }

  async fetchJobs(item: string) {
    await this.store.getJobs(item);
  }

  async fetchNewsItems() {
    await this.store.getNewsItem();
  }

  async fetchJobsItems() {
    await this.store.getJobItem();
  }

  async fetchJobsTotalNumber() {
    await this.store.getJobTotal();
  }

  async fetchTopicLists() {
    await this.store.getTopics();
  }


  async getWatch(item: string) {
    await this.store.getWatch(item);
  }

  async getCompany(company: string) {
    if (company == null){
      return
    }
    await this.store.getCompanyInfo(company);
  }

  async getCompanyList() {
    await this.store.getCompanies();
  }



  async getNewsTrends(item) {
    await this.store.getTrend(item);
  }

  async getX1FCompanies() {
    await this.store.getX1FCompanies();

  }

  async fetchVersion() {
    await this.store.getVersion();

  }

  async getStock(stock: string) {
    await this.store.getStockTimeSerie(stock)
  }

  async getStockNews(stock: string) {
    await this.store.getStockNews(stock)
  }

  async getHints() {
    await this.store.getHints()
  }

  async getImportantHints() {
    await this.store.getImportantHints()
  }
}

import { createContext, useContext } from 'react'
import { ReflectiveInjector } from 'injection-js'
import { Store } from "./mesh/store";
import { Controller } from "./mesh/controller";

export const InjectorContext = createContext(
  ReflectiveInjector.resolveAndCreate([Controller, Store])
);

export const useDependency = <T = any>(token: any): T => useContext(InjectorContext).get(token);

export class Helper {

  get getBaseUrl(){
    let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (baseUrl.includes('localhost')){
      baseUrl = "http://localhost:8080"
    }
    return baseUrl

  }

  banken = [
   'Deutsche Bank', 'Commerzbank', 'Atruvia', 'dwp', 'Bayern LB', 'Börse Stuttgart',
    'LBBW', 'DZ Bank', 'HELABA', 'Nord LB', 'Deka', 'apoBank', 'parcIT', 'Hays', 'FI', 'Sparkasse', ''
  ];

  x1f = ['IKOR', 'FSP', 'e2 Security', 'ADWEKO', 'matrix', 'Informationsfabrik', 'fink.', 'BasisTeam', 'XPACT', 'COMPIRICUS', ''];

}
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StockApiResponse } from '../../../mesh/entity';

interface CandleChartProps {
  stockData: StockApiResponse;
}

const CandleChart: React.FC<CandleChartProps> = ({ stockData }) => {

  if (stockData['response'] == null) {
    return <p>Keine Daten zu der Aktie</p>;
  }


  const data = Object.entries(stockData.response)
    .slice(240, 300)  // Nur die ersten 60 Einträge nehmen
    .map(([timestamp, dailyData]) => ({
      x: new Date(dailyData.tm.slice(0,10)),
      y: [
        parseFloat(String(Math.round(Number(dailyData.o) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.h) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.l) * 100) / 100)),
        parseFloat(String(Math.round(Number(dailyData.c) * 100) / 100)),
      ]
    }));

  const series = [{
    name: stockData.info.symbol,
    data: data
  }];

  const options: ApexOptions = {
    chart: {
      type: 'candlestick',
      height: 350
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      tooltip: {
        enabled: true
      }
    }
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="candlestick" height={350} />
    </div>
  );
};

export default CandleChart;
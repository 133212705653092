import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { News } from '../../../mesh/entity';
import moment from 'moment/moment';


function MeshCompanyAC() {
  const c = useDependency<Controller>(Controller);
  const [company, setCompany] = useState(localStorage.getItem('company'));


  useEffect(() => {
    if (company != '' && c.store.selectedCompany != company) {
      c.getCompany(company);
    }

    if (c.store.companies.length == 0) {
      c.getCompanyList();
    }
    if (!company){
      setCompany('Atruvia')
    }


  }, [c.store.companyWatch, c.store.companies, company]);

  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function handleClick(company: string) {
    setCompany(company);
    localStorage.setItem('company', company);
  }


  return (
    <>
      {c.store.companies && (
        <Grid lg={12} item key={'Topic'} display={'flex'}>
          <Box flex={2} sx={{ p: 1 }}>
            <Card>
              <CardHeader
                title={
                  'Companies'
                }
              />
              <CardContent>
                <Grid>
                  <ul style={{ listStyle: 'none' }}>
                    {c.store.companies
                      .slice()
                      .sort()
                      .map((c: string) => {
                          return <li key={c}>
                            <Button className={`${company === c ? 'Mui-selected' : ''}`}
                                    onClick={() => handleClick(c)}>{c.trim()}</Button>
                          </li>;
                        }
                      )}
                  </ul>
                </Grid>
                <Typography variant="h6" gutterBottom>
                </Typography>
              </CardContent>
            </Card>
          </Box>

          {c.store.companyWatch && (
            <Grid flex={10}>
              <h2 style={{ marginLeft: '10px' }}>{company}</h2>

              <Grid display={'flex'}>

                <Grid flex={8} sx={{ p: 1 }}>
                  <Card>
                    <CardHeader
                      title={
                        'NEWS: ' + c.store.companyWatch.news.length
                      }
                    />
                    <CardContent>
                      <Grid container spacing={2} style={{ maxHeight: '1000px', overflow: 'auto' }}>
                        {c.store.companyWatch.news
                          .map((news: News) => {
                              let publisher = '';

                              publisher = JSON.parse(news.publisher
                                .replaceAll('\'', '"')).title;

                              return (
                                < Grid lg={6} item key={news.id}>
                                  <Card
                                    style={{ minHeight: '500px' }}>
                                    <CardHeader
                                      title={<Grid>
                                        <Box>

                                          {news.img &&
                                            <img src={news.img} alt="IMAGE"
                                                 style={{ height: '200px', width: '100%', objectFit: 'cover' }} />
                                          }
                                          {!news.img &&
                                            <Box style={{ display: 'block', textAlign: 'center' }}>
                                              <img src={'static/images/mesh/no.png'} alt="IMAGE"
                                                   style={{ height: '200px', width: '80%' }} />

                                            </Box>
                                          }
                                        </Box>

                                        <Box display="flex" style={{ marginTop: '10px' }}>
                                          <Box flex={12}>
                                            <a target="_blank" rel="noreferrer" href={news.url}>
                                              {truncateString(news.title, 120)}
                                            </a>
                                          </Box>

                                          <Box>
                                            <Chip key={'S_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                                  label={news.search_item} color="warning" />
                                          </Box>
                                        </Box>
                                      </Grid>
                                      }
                                      subheader={

                                        publisher + ' - ' + moment(news.published_date).format('DD.MM.YYYY')
                                      }
                                    />

                                    <CardContent>
                                      <Grid display="flex" flexDirection={'column'}
                                            style={{ justifyContent: 'space-between' }}>

                                        <Box>
                                          {truncateString(news.short_desc, 250)}
                                        </Box>

                                        <Box style={{ marginTop: '10px' }}>

                                          {news.trend_hits && (
                                            news.trend_hits.slice(1, -1).split(',')
                                              .map((trend: string) =>
                                                <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                                      label={trend}
                                                      style={{ margin: '5px' }} color="success" />
                                              ))}

                                          {news.keywords.length > 2 && news.keywords.replace(']', '')
                                            .replace('[', '')
                                            .split(',')
                                            .slice(0, 3)
                                            .map((topic: string) =>
                                              <Chip key={'B_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}

                                                    label={topic
                                                      .replaceAll('\'', '')
                                                    }
                                                    style={{ margin: '5px' }} color="primary" />
                                            )}

                                        </Box>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              );
                            }
                          )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid flex={6} sx={{ p: 1 }}>
                  <Card>
                    <CardHeader
                      title={
                        'Eingesetzte Technologien & Methoden'
                      }
                    />
                    <CardContent>
                      <Grid>

                        {c.store.companyWatch && (
                          <ReactMarkdown>

                            {c.store.companyWatch.tech_stack}
                          </ReactMarkdown>
                        )}

                      </Grid>
                      <Typography variant="h6" gutterBottom>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

              </Grid>
            </Grid>
          )}
        </Grid>
      )}

    </>
  );
}


export const MeshCompany = observer(MeshCompanyAC);

import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { News } from '../../../mesh/entity';
import moment from 'moment/moment';
import { Helper } from '../../../utils/helper';

const helper = new Helper();

function MeshRegAC() {
  const c = useDependency<Controller>(Controller);
  const [item, setItem] = useState(localStorage.getItem('watchItem'));


  useEffect(() => {
    if (item != '' && c.store.companyItem != item) {
      c.getWatch(item);
    }

    if (!item){
      setItem('AI Act')
    }

  }, [c.store.companyItem, item]);

  function handleClick(topic: string) {
    setItem(topic);
    localStorage.setItem('watchItem', topic);
  }

  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  return (
    <>
      {c.store.watch && (
        <Grid lg={12} item key={'Topic'} display={'flex'}>
          <Grid flex={2} sx={{ p: 1 }}>
            <Card>
              <CardHeader
                title={
                  'Topic'
                }
              />
              <CardContent>
                <Grid display="flex">
                  <ul style={{ listStyle: 'none' }}>
                    {c.store.trendTopicsName
                      .slice()
                      .sort()
                      .map((topic: string) => {
                          return <li key={topic} style={{ textAlign: 'left' }}>
                            <Button
                              className={`${item === topic ? 'Mui-selected' : ''}`}
                              onClick={() => handleClick(topic)}
                            >{topic.trim()}</Button>
                          </li>;
                        }
                      )}
                  </ul>
                </Grid>
                <Typography variant="h6" gutterBottom>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid flex={9}>
            <h2 style={{ marginLeft: '10px' }}>{item}</h2>


            <Grid flex={10} item key={'Topic'} display={'flex'}>


              <Box flex={3} sx={{ p: 1 }}>
                <Card>
                  <CardHeader
                    title={
                      'Regulatorik: ' + c.store.watch.bafin.concat(c.store.watch.esma).length
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2} style={{ maxHeight: '400px', overflow: 'auto' }}>
                      {c.store.watch.bafin.concat(c.store.watch.esma)
                        .map((news: News) => {
                            let publisher = '';
                            if (news.search_item == 'BaFin Nachrichten'
                              || news.search_item == 'BaFin Konsultation'
                              || news.search_item == 'BaFin Newspapers'
                            ) {
                              publisher = news.search_item;
                            } else {
                              publisher = JSON.parse(news.publisher
                                .replaceAll('\'', '"')).title;
                            }

                            return (
                              < Grid lg={12} item key={news.id}>
                                <Card
                                  sx={{ px: 1 }}
                                  style={{ minHeight: '300px' }}>
                                  <CardHeader
                                    title={
                                      <a target="_blank" rel="noreferrer" href={news.url}>
                                        {news.title}
                                      </a>
                                    }
                                    subheader={
                                      publisher + ' - ' + moment(news.published_date).format('DD.MM.YYYY')
                                    }
                                  />

                                  <CardContent>
                                    <Grid display="flex">
                                      <Box flex={5}>
                                        <Typography>
                                          {news.short_desc}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Typography variant="h6" gutterBottom>

                                      {news.trend_hits && (
                                        news.trend_hits.slice(1, -1).split(',')
                                          .map((trend: string) =>
                                            <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                                  label={trend}
                                                  style={{ margin: '5px' }} color="success" />
                                          ))}

                                      {news.keywords.length > 2 && news.keywords.replace(']', '')
                                        .replace('[', '')
                                        .split(',')
                                        .slice(0, 3)
                                        .map((topic: string) =>
                                          <Chip key={'B_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}

                                                label={topic
                                                  .replaceAll('\'', '')
                                                }
                                                style={{ margin: '5px' }} color="primary" />
                                        )}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          }
                        )}
                    </Grid>
                    <Typography variant="h6" gutterBottom>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <Box flex={3} sx={{ p: 1 }}>
                <Card>
                  <CardHeader
                    title={
                      'News: ' +
                      c.store.watch.news
                        .filter((news) => !news.search_item.includes('BaFin') && news.search_item !== 'ESMA')
                        .length
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2} style={{ maxHeight: '400px', overflow: 'auto' }}>
                      {c.store.watch.news
                        .filter((news) => !news.search_item.includes('BaFin') && news.search_item !== 'ESMA')
                        .map((news: News) => {

                            let publisher = '';

                            publisher = JSON.parse(news.publisher
                              .replaceAll('\'', '"')).title;

                            return (
                              < Grid lg={12} item key={news.id}>
                                <Card
                                  style={{ minHeight: '500px' }}>
                                  <CardHeader
                                    title={<Grid>
                                      <Box>

                                        {news.img &&
                                          <img src={news.img} alt="IMAGE"
                                               style={{ height: '200px', width: '100%', objectFit: 'cover' }} />
                                        }
                                        {!news.img &&
                                          <Box style={{ display: 'block', textAlign: 'center' }}>
                                            <img src={'static/images/mesh/no.png'} alt="IMAGE"
                                                 style={{ height: '200px', width: '80%' }} />

                                          </Box>
                                        }
                                      </Box>

                                      <Box display="flex" style={{ marginTop: '10px' }}>
                                        <Box flex={12}>
                                          <a target="_blank" rel="noreferrer" href={news.url}>
                                            {truncateString(news.title, 120)}
                                          </a>
                                        </Box>

                                        <Box>
                                          <Chip key={'S_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                                label={news.search_item} color="warning" />
                                        </Box>
                                      </Box>
                                    </Grid>
                                    }
                                    subheader={

                                      publisher + ' - ' + moment(news.published_date).format('DD.MM.YYYY')
                                    }
                                  />

                                  <CardContent>
                                    <Grid display="flex" flexDirection={'column'}
                                          style={{ justifyContent: 'space-between' }}>

                                      <Box>
                                        {truncateString(news.short_desc, 250)}
                                      </Box>

                                      <Box style={{ marginTop: '10px' }}>

                                        {news.trend_hits && (
                                          news.trend_hits.slice(1, -1).split(',')
                                            .map((trend: string) =>
                                              <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                                    label={trend}
                                                    style={{ margin: '5px' }} color="success" />
                                            ))}

                                        {news.keywords.length > 2 && news.keywords.replace(']', '')
                                          .replace('[', '')
                                          .split(',')
                                          .slice(0, 3)
                                          .map((topic: string) =>
                                            <Chip key={'B_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}

                                                  label={topic
                                                    .replaceAll('\'', '')
                                                  }
                                                  style={{ margin: '5px' }} color="primary" />
                                          )}

                                      </Box>
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          }
                        )}
                    </Grid>
                    <Typography variant="h6" gutterBottom>
                    </Typography>
                  </CardContent>
                </Card>
              </Box>


            </Grid>
            <Grid flex={12} sx={{ p: 1 }}>
              <Card>
                <CardHeader
                  title={
                    'Information'
                  }
                />
                <CardContent>
                  <Grid display="flex">

                    {c.store.watch && (
                      <ReactMarkdown>
                        {c.store.watch.info}
                      </ReactMarkdown>
                    )}

                  </Grid>
                  <Typography variant="h6" gutterBottom>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}


export const MeshWatchReg = observer(MeshRegAC);

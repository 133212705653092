import { Typography, Avatar, Grid, Box, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

function PageHeader() {

  const theme = useTheme();

  return (
    <Grid>
      <Card style={{ width: '97%', textAlignLast:"center", margin:"25px"}}>
        <Grid display={'flex'}>
          <Box>
            <img src={'/static/images/mesh/B&P.png'} alt="IMAGE"
                 style={{ height: '100px', margin: '10px', padding: '0', border: 'none' }} />
          </Box>
          <Box flex={8}>
            <Typography variant="h3" component="h3" gutterBottom style={{ marginTop: '5px' }}>
              Welcome to the new Consultants Cockpit!
            </Typography>
            <Typography variant="subtitle2">
              Verschaffe dir einen schnellen Überblick über:
            </Typography>
            <Typography variant="subtitle2">
              die Technologie unser Kunden, aktuelle Regulatorik oder die neusten Nachrichten
            </Typography>
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
}

export default PageHeader;

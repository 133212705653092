import { Box, Card, CardContent, CardHeader, Chip, Grid, Pagination, Select, Typography } from '@mui/material';
import { Job } from '../../../mesh/entity';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import TextField from '@mui/material/TextField';


function countOccurrences(input: Job[]): { element: string, count: number }[] {
  const count: { [key: string]: number } = {};

  input
    .filter((job) => moment(job.created_at) >= moment().subtract(15, 'days'))
    .forEach((j) => {
      const str = j.company.name;
      if (count[str]) {
        count[str] += 1;
      } else {
        count[str] = 1;
      }
    });
  return Object.keys(count).map(key => ({ element: key, count: count[key] }))
    .sort((a, b) => b.count - a.count);
}

function parseStringBoolean(stringValue) {
  try {
    return JSON.parse(stringValue);
  } catch (e) {
    return false;
  }
}


function MeshJobsAC() {
  const c = useDependency<Controller>(Controller);

  const [filterTime, setFilterTime] = useState('all');
  const [filterTopic, setFilterTopic] = useState('all');
  const [filterPlayer, setFilterPlayer] = useState('all');
  const [filterOpen, setFilterOpen] = useState('true');
  const [filterString, setFilterString] = useState('');
  const [filterBlacklist, setFilterBlacklist] = useState('');
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 8;
  const count = Math.ceil(c.store.filteredJobs.length / ITEMS_PER_PAGE);
  const [item, setItem] = useState(localStorage.getItem('jobItem'));
  const [lastItem, setLastItem] = useState('');

  useEffect(() => {



    setLastItem(item);
    if (item != 'none' && item != lastItem && lastItem != '') {
      c.fetchJobs(item);
    }

    if (item != 'none' && c.getJobs.length === 0) {
      c.fetchJobs(item);
    }

    if (item == null) {
      c.fetchJobs('hot');
    }

  }, [item]);

  useEffect(() => {

    if (c.store.version == '') {
      c.fetchVersion();
    }

    if (c.getJobsItems.length === 0) {
      c.fetchJobsItems();
      c.fetchJobsTotalNumber();
    }
  }, [c.store.jobItem, , c.store.version]);


  useEffect(() => {
    filter();
  }, [filterTime, filterPlayer, filterString, filterTopic, filterOpen, filterBlacklist, c.store.jobs ]);


  function filter() {
    c.store.filteredJobs = c.getJobs
      .filter((job) =>
        (job.hot_hits && job.hot_hits.includes(filterTopic)) ||
        (job.trend_hits && job.trend_hits.includes(filterTopic)) ||
        filterTopic == 'all')
      .filter((job) => job.active == parseStringBoolean(filterOpen) || filterOpen == 'all')
      .filter((job) => moment(job.created_at) <= moment().subtract(filterTime, 'days') || filterTime == 'all')
      .filter((job) => job.company.name == filterPlayer || filterPlayer == 'all')
      .filter((job) => job.job_title.toLowerCase().includes(filterString.toLowerCase()) || filterString == '')
      .filter((job) => {
        if (!filterBlacklist || filterBlacklist.trim() === '') {
          return true;
        }
        return !job.job_title.toLowerCase().includes(filterBlacklist.toLowerCase());
      });
  }

  const topics = [...c.store.hotTopicsName];

  const handleFilter = (event) => {
    if (event.target.name == 'time')
      setFilterTime(event.target.value);
    if (event.target.name == 'player')
      setFilterPlayer(event.target.value);
    if (event.target.name == 'string')
      setFilterString(event.target.value.toLowerCase());
    if (event.target.name == 'topic')
      setFilterTopic(event.target.value);
    if (event.target.name == 'open')
      setFilterOpen(event.target.value);
    if (event.target.name == 'blacklist')
      setFilterBlacklist(event.target.value);
  };


  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleItemChange = (value) => {
    setItem(value);
    localStorage.setItem('jobItem', value);
  };


  const getDataForCurrentPage = () => {
    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return c.store.filteredJobs
      .slice(start, end)
      .sort(function(a: Job, b: Job) {
        return Number(b.id) - Number(a.id);
      });
  };

  return (
    <>
      <Box display="flex" style={{ flexWrap: 'wrap' }}>
        <Typography flex={2} variant="h3" style={{ marginLeft: '10px', marginBottom: '10px' }}>
          Jobs {c.store.filteredJobs.length} / {c.store.total}</Typography>
        <Box flex={2}></Box>
        <Box flex={1}>
          <Pagination style={{ flex: '1' }} count={count} page={page} onChange={handleChange} />
        </Box>

      </Box>

      <Box display="flex" style={{ flexWrap: 'wrap' }}>
        <Box>
          <Select
            name={'item'}
            defaultValue={item}
            onChange={(e) => handleItemChange(e.target.value)}
            style={{ minWidth: '200px', margin: '5px' }}
          >
            <MenuItem value={'all'}>Alle</MenuItem>
            <MenuItem disabled={true}>Highlights</MenuItem>
            <MenuItem value={'hot'}>Kompetenz</MenuItem>
            <MenuItem disabled={true}> Banken</MenuItem>

            {c.getJobsItems.slice().sort().map((item_opt) => {
                return (
                  <MenuItem key={item_opt} value={item_opt}>{item_opt}</MenuItem>
                );
              }
            )}
          </Select>
        </Box>

        <Box>
          <Select onChange={handleFilter}
                  defaultValue={'true'}
                  name={'open'}
                  style={{ width: '200px', margin: '5px' }}
          >
            <MenuItem value={'all'}><em>Alle Stellen </em> </MenuItem>
            <MenuItem value={'true'}>offen</MenuItem>
            <MenuItem value={'false'}>besetzt</MenuItem>
          </Select>
        </Box>

        <Box>
          <Select
            onChange={handleFilter}
            defaultValue={'all'}
            name={'topic'}
            style={{ width: '200px', margin: '5px' }}
          >
            <MenuItem value={'all'}><em>Thema</em> </MenuItem>

            {topics.map((name) => {
                const key = name;
                if (name.length >= 17) {
                  name = name.slice(0, 20).concat('...');
                }
                return (
                  <MenuItem key={key} value={key}>
                    {name}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </Box>

        <Box>
          <Select
            onChange={handleFilter}
            defaultValue={'all'}
            name={'player'}
            style={{ width: '200px', margin: '5px' }}
          >
            <MenuItem value={'all'}><em>Company</em> </MenuItem>
            {countOccurrences(c.getJobs).map((count) => {
                let name = count.element;
                if (count.element.length >= 17) {
                  name = count.element.slice(0, 20).concat('...');
                }
                return (
                  <MenuItem key={count.element} value={count.element}>
                    {name}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </Box>

        <Box>
          <Select onChange={handleFilter}
                  defaultValue={'all'}
                  name={'time'}
                  style={{ width: '200px', margin: '5px' }}
          >
            <MenuItem value={'all'}><em>Alle Zeiträume </em> </MenuItem>
            <MenuItem value={'60'}>Älter als 2 Monate</MenuItem>
          </Select>
        </Box>


        <Box>
          <TextField
            label="Filter"
            name={'string'}
            onChange={handleFilter}
            fullWidth={true}
            style={{ marginBottom: '10px', width: '200px', margin: '5px' }}
          />
        </Box>

        <Box>
          <TextField
            label="Blacklist"
            name={'blacklist'}
            onChange={handleFilter}
            fullWidth={true}
            style={{ marginBottom: '10px', width: '200px', margin: '5px', marginLeft:'5px' }}
          />
        </Box>

      </Box>


      {c.store.filteredJobs && (
        <Grid container spacing={4}>

          {getDataForCurrentPage()
            .sort(function(a: Job, b: Job) {
              return moment(b.created_at).diff(moment(a.created_at));
            })
            .map((job: Job) => {

                let date = moment(job.created_at).format('DD.MM.YYYY');

                if (date == 'Invalid date') {
                  date = job.created_at;
                }

                return (
                  <Grid lg={3} md={3} sm={12} item key={job.id}>
                    <Card sx={{ px: 1 }}>
                      <CardHeader

                        title={
                          <Box>
                            {job.active &&
                              <a target="_blank" rel="noreferrer" href={job.url}>
                                {job.job_title}
                              </a>}

                            {!job.active &&
                              <a>
                                {job.job_title} - <a style={{ color: 'red' }}>VERGEBEN</a>
                              </a>}
                          </Box>
                        }
                        subheader={
                          <div style={{ marginTop: '5px' }}>
                            <a href={job.company.url}> {
                              job.company.name
                              + ' - '
                              + job.location}
                            </a>
                            <div> Anzahl Kontakte: {job.company.count}</div>
                          </div>
                        }
                      />

                      <CardContent>
                        <Box>
                          <Tooltip id="my-tooltip" clickable
                                   render={({ content, activeAnchor }) => (
                                     <div style={{ display: 'flex', flexWrap: 'wrap', width: '450px' }}>
                                       {activeAnchor?.getAttribute('data-some-relevant-attr')}
                                     </div>
                                   )}
                          />

                          <Typography variant="h6" gutterBottom>

                            {job.trend_hits && (
                              job.trend_hits.slice(1, -1).split(',')
                                .map((trend) =>
                                  <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                        label={trend.replaceAll('"', '')}
                                        style={{ margin: '5px' }} color="success" />
                                ))}

                            {job.hot_hits && (
                              job.hot_hits.slice(1, -1).split(',')
                                .map((hot) =>
                                  <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                        label={hot.replaceAll('"', '')}
                                        style={{ margin: '5px' }} color="error" />
                                ))}

                            {job.topics.replace(']', '')
                              .replace('[', '')
                              .split(',')
                              .slice(0, 2)
                              .map((topic) =>
                                <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                                      label={topic
                                        .replaceAll('\'', '')
                                      }
                                      style={{ margin: '5px' }} color="primary" />
                              )}
                          </Typography>

                          <Box style={{ display: 'flex' }}>
                            <Box flex={5}></Box>
                            <Box flex={1}>
                              {date}
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>

                  </Grid>
                );
              }
            )}

        </Grid>
      )
      }
    </>
  );
}

export const MeshJobs = observer(MeshJobsAC);

import { RouteObject } from 'react-router';
import { Dashboard } from './content/dashboards/Mesh';
import Login from './content/dashboards/Mesh/MeshLogin';


const routes: RouteObject[] = [
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '',
    element: <Dashboard />
  }
];

export default routes;

import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependency } from '../../../injections';
import { Controller } from '../../../mesh/controller';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';


function MeshX1FAC() {
  const c = useDependency<Controller>(Controller);
  const [x1f_company_name, setX1f_company_name] = useState(localStorage.getItem('x1f_company_name'));


  useEffect(() => {



    if (c.store.x1f.length == 0) {
      c.getX1FCompanies();
    }

    setX1f_company_name('x1f');
    localStorage.setItem('x1f', 'x1f');

  }, [c.store.x1f]);

  function truncateString(str: string, num: number): string {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }

  function handleClick(x1f_company_name: string) {
    setX1f_company_name(x1f_company_name);
    localStorage.setItem('x1f', x1f_company_name);
  }

  var x1f_company = c.store.x1f.find(selected_x1f => selected_x1f.company_name === x1f_company_name);



  return (
    <>
      {c.store.x1f && (
        <Grid lg={12} item key={'Topic'} display={'flex'}>
          <Grid lg={3}>
            <Card>
              <CardContent>
                <Grid>
                  <ul style={{ listStyle: 'none' }}>
                    <li key="x1f">
                      <Button className={`${x1f_company_name === 'x1f' ? 'Mui-selected' : ''}`}
                              onClick={() => handleClick('x1f')}>x1f</Button>
                    </li>
                    {c.store.x1f
                      .map(x1f => x1f.company_name)
                      .slice()
                      .filter(c => c !== 'x1f')
                      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                      .map((c: string) => {
                          return <li key={c}>
                            <Button className={`${x1f_company_name === c ? 'Mui-selected' : ''}`}
                                    onClick={() => handleClick(c)}>{c}</Button>
                          </li>;
                        }
                      )}
                  </ul>
                </Grid>
                <Typography variant="h6" gutterBottom>
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          {x1f_company && (

            <Grid flex={9} sx={{ p: 1 }}>
              <Grid lg={12}>
                <h2 style={{ marginLeft: '20px' }}>
                  <a target="_blank" rel="noreferrer" href={x1f_company.url}>
                    {x1f_company.url
                      .replace('https://', '')
                      .replace('www.', '')
                      .replace('/de', '')
                      .slice(0, -1)}
                  </a>
                </h2>
                <div style={{ marginLeft: '20px' }}>
                  {x1f_company.tags.slice(1, -1).split(',')
                    .map((hot) =>
                      <Chip key={'J_' + Math.floor(Math.random() * (1 - 9999 + 1) + 9999)}
                            label={hot.replaceAll('"', '')}
                            style={{ margin: '5px' }} color="success" />
                    )
                  }
                </div>
              </Grid>
              <Grid lg={12} display={'flex'}>

                <Grid lg={6} sx={{ p: 1 }}>
                  <Card>
                    <CardContent>
                      <Grid>
                        {x1f_company && (<>
                            <Typography variant="h5">
                              Zielgruppe:
                            </Typography>

                            <Typography>
                              {x1f_company.target}
                            </Typography>
                            <br />
                            <Typography variant="h5">
                              Standort:
                            </Typography>

                            <Typography>
                              {x1f_company.location}
                            </Typography>
                            <br />

                            <Typography variant="h5">
                              Anzahl Mitarbeiter:
                            </Typography>

                            <Typography>
                              {x1f_company.employees}
                            </Typography>
                          </>
                        )}

                      </Grid>
                      <Typography variant="h6" gutterBottom>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid lg={6} sx={{ p: 1 }}>
                  <Box style={{ minHeight: '200px' }}>
                    <img src={'/static/images/logos/' + x1f_company.company_name + '.png'} alt="IMAGE"
                         style={{ maxHeight: '160px', maxWidth: '600px' }} />
                  </Box>
                </Grid>

              </Grid>

              <Grid lg={12} display={'flex'}>
                <Grid lg={6} sx={{ p: 1 }}>
                  <Card style={{ minHeight: '200 px' }}>
                    <CardHeader
                      title={
                        'Solutions'
                      }
                    />
                    <CardContent>
                      <Grid>

                        {x1f_company && (
                          <ReactMarkdown>
                            {x1f_company.solution}
                          </ReactMarkdown>
                        )}

                      </Grid>
                      <Typography variant="h6" gutterBottom>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid lg={6} sx={{ p: 1 }}>
                  <Card style={{ minHeight: '200 px' }}>
                    <CardHeader
                      title={
                        'Skills'
                      }
                    />
                    <CardContent>
                      <Grid>

                        {x1f_company && (
                          <ReactMarkdown>
                            {x1f_company.skills}
                          </ReactMarkdown>
                        )}

                      </Grid>
                      <Typography variant="h6" gutterBottom>
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}


export const MeshX1F = observer(MeshX1FAC);

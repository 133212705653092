import { Box, Container, Typography, styled } from '@mui/material';
import React from 'react';
import { useDependency } from '../../injections';
import { Controller } from '../../mesh/controller';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  const c = useDependency<Controller>(Controller);

  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: 'block', md: 'flex' }}
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box style={{marginLeft:'-250px'}}>
          <Typography style={{fontSize:'15px'}}>Disclaimer: Folgende Inhalte sind mit ChatGPT erzeugt</Typography>
          <Typography style={{fontSize:'12px'}}> - Topic Modelling (blaue Chips)</Typography>
          <Typography style={{fontSize:'12px'}}> - Trend Themen</Typography>
          <Typography style={{fontSize:'12px'}}> - Company: Eingesetzte Technologien & Methoden</Typography>
          <Typography style={{fontSize:'12px'}}> - Regulatorik: Timeline</Typography>
        </Box>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Hand-Crafted Version {c.store.version} by {'MTM'}

        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;

import React, { useState } from 'react';
import {
  Button,
  Card, CardContent, CardHeader, Container, Divider, Grid, IconButton, InputAdornment
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Helper } from '../../../utils/helper';


const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const helper = new Helper();
  const endpointLogin = helper.getBaseUrl + `/api/login`;

  const handleLogin = async () => {
    try {
      const response = await fetch(endpointLogin, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });
      if (!response.ok) {
        throw new Error('Login fehlgeschlagen');
      }
      const data = await response.json();
      localStorage.setItem('token', data.access_token);
            window.location.href = '/'; // Gehe zur Login-Seite
    } catch (error) {
      console.log(error);
    }
  };

  return (

    <>

      <Container maxWidth="md" style={{marginTop:"10%"}}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Herzlich Willkommen" />
              <Divider />
              <CardContent style={{textAlign:"center"}}>

                  <TextField
                    label="Benutzername"
                    name="string"
                    onChange={(e) => setUsername(e.target.value)}
                    style={{ margin: '5px' }}
                    value={username}
                  />

                <TextField
                  label="Passwort"
                  name="Passwort"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  style={{ margin: '5px' }}
                  value={password}
                />

                <Button onClick={handleLogin} variant="outlined" size={'small'} style={{marginTop:'15px'}}>Einloggen</Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>

  );
};

export default Login;